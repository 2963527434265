.wrapper {
  position: relative;
}

.days {
  display: flex;
  gap: 0.2rem;
}

.errMsg {
  position: absolute;
  color: #dc3545;
  font-size: 0.7rem;
  bottom: -1rem;
  left: 0.5rem;
}

.dayBtn {
  width: 2.8rem;
  padding: 6px;
  border-radius: 4px;
  background: #4b6687;
  color: white;
  font-weight: 400;
}

.off {
  background: none;
  color: #4B6687;
}