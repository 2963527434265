@import 't4b-core-frontend/src/css/colors';

.header,
.footer {
  min-height: 37px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.title {
  font-size: 17px;
  font-weight: 450;
  padding-left: 10px;
  color: white;
  white-space: nowrap;
  margin: 0;
  text-transform: capitalize;
}

.button {
  background: none;
  border: none;
  margin-right: 5px;
  color: white;
}

.blockOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  background-color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
