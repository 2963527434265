@import '~bootstrap/scss/bootstrap';
@import 't4b-core-frontend/src/css/colors';
@import './styles/bootstrap-old';
@import './styles/backbrounds';

//main
@font-face {
  font-family: "Open Sans";
  src: url("../public/OpenSans-VariableFont_wdth,wght.ttf");
}

body > .container-fluid {
  padding: 0 !important;
}

html {
  overflow: hidden;
  width: 100%;
}

.app {
  background-color: #e5eeff;
  position: relative;
}

#lang-dropdown {
  display: none;
}

.app-login-container {
  margin-top: 70px;
}

.app-navbar {
  button {
    margin: auto;
  }

  .navbar-border {
    display: flex;
    align-items: center;
  }
}

.nav-dark.card-header div {
  color: #324766 !important;
  font-weight: 500;
}

.nav-dark.card-header .invalid-err {
  color: red !important;
}

.disabled-link {
  color: $btn-link-disabled-color !important;
}

.label-sm .label-main {
  font-weight: 400;
}

.user-naw-link.active {
  background-color: #7088aa !important;
}

.navi-blue {
  color: #2c405a;
}

.border-left-db-connection {
  border-left: 3px solid #4b8581 !important;
}

.border-left-mail-sender {
  border-left: 3px solid #344e6f !important;
}

.app-light-color {
  background-color: #f2f7fc !important;
}

.license {
  border-top: 1px solid #3e5068;
}

.flex-container {
  display: flex;
  margin: 0 -5px;
}

.flex-item {
  flex: 1;
}

.flex-item:first-child {
  margin-left: 0;
}

//input

//fix layout for bootstrap
.input-main.form-control.is-invalid {
  background-image: none !important;
  padding-right: 10px !important;
}

.form-control.is-invalid{
  background-image: none;
}

.input-tooltip {
  position: absolute;
  right: 5px;
  z-index: 1000;
  background-color: white;
  color: #2c405a;
}

.input-padding {
  padding-right: 38px !important;
}

.input-padding-sm {
  padding-right: 28px !important;
}

.password-input-padding {
  padding-right: 69px !important;
}

.input-feedback {
  font-size: 12px;
  font-weight: normal;
}

.app-dictionary-row-input {
  input {
    height: 35px !important;
  }
}

.app-dictionary-row {
  height: 35px !important;
}

.product-name-input {
  width: 260px;
  height: 35px;
  margin-left: 0 !important;
}

.product-name-input-sm {
  width: 175px;
  height: 30px;
  margin-left: 0 !important;
}

.icon-main {
  svg {
    width: 20px;
    height: 20px;
  }
}

.icon-small {
  svg {
    width: 15px;
    height: 15px;
  }
}

.nav-user .dropdown-menu {
  left: auto;
  right: 10px;
}

.width-49 {
  width: 49%;
}

.app-color-text {
  color: $app-color-text;
}

.nav-item-drop-down {
  color: #16181b;
}

.label-main,
.label-main-not-req {
  color: $app-color-text;
  font-size: 1.1rem;
  line-height: 1.1;
}

.value-main {
  font-size: 1.1rem;
  line-height: 1.1;
}

.label-main,
.value-main {
  font-weight: 450 !important;
}

.label-main-wrapper .label-required-mark {
  position: absolute;
  right: -8px;
  bottom: 2px;
}

.label-main-not-req {
  font-weight: 400;
  opacity: 0.8 !important;
}

.label-small {
  color: $app-color-text;
  font-size: 1rem;
}

.invalid-icon-wrap {
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.checkbox-lg {
  width: 18px;
  height: 18px;
}

.flex-1 {
  flex: 1;
}

.timeOnlyPicker {
  width: 124px;
}

.timeOnlyPicker-ms {
  width: 125px;
}

.fullDatePicker {
  width: 190px;
}

.fullDatePicker-ms {
  width: 225px;
}

.datePicker {
  width: 120px;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-capital {
  &:first-letter {
    text-transform: capitalize;
  }
}

.vh-full {
  height: calc(100vh - 170px);
}

.vh-full-scroll {
  height: calc(100vh - 230px);
}

.vh-full-header {
  height: calc(100vh - 58px);
}

.vh-users {
  height: calc(100vh - 200px);
}

.vh-user-card {
  height: calc(100vh - 240px);
}

.border-none {
  border: none !important;
}

.err-message-tooltip {
  padding: 2px 10px;
  margin-bottom: 0.3rem;
  color: red;
}

.min-500 {
  min-width: 500px;
}

.min-width-100 {
  min-width: 100px;
}

.min-width-5 {
  min-width: 5rem;
}

.p-relative {
  position: relative;
}

.mw-250 {
  min-width: 250px;
}

.mw-350 {
  min-width: 350px;
}

.m {
  min-width: 600px;
}

.min-50 {
  min-width: 50%;
}

.maxH-125 {
  max-height: 125px !important;
}

.w-500 {
  width: 500px !important;
}

.hover {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid $app-color-light !important;
  }
}

.input-error {
  border: 1px solid #dc3545;
  &::placeholder {
    color: #dc3545;
  }
}

.hover-shadow {
  &:hover {
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  }
}

.app-summary-grid {
  display: grid;
  width: 50%;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1690px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 2050px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.word-break {
  word-wrap: break-word !important;
}

.w-90 {
  width: 90%;
}

.app-w-30 {
  width: 30%;
}

.app-w-50 {
  width: 50px;
}

.app-border-color {
  color: $app-border-color;
}

.app-border-main {
  border: 1px solid #4b6687;
}

.app-border-bottom-main {
  border-bottom: 1px solid #4b6687;
}

.navLink,
.dropdownLink {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.sidebar-opened-dropdown {
  width: 100%;
  overflow: hidden;
}

.sidebar-hidden-dropdown {
  width: 0;
  height: 0;
  overflow: hidden;
}

.navLink.active {
  background: #397b8e;
}

.dropdownLink {
  width: 100%;
  padding: 0;

  .dropdown-label {
    display: block;
    max-width: 182px;
    width: fit-content;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.dropdownLink.active {
  width: 100%;

  .dropdown-label {
    border-width: inherit;
    border-bottom: 1px solid white;
  }
  .inactive {
    border: none;
  }
}

.truncate-text {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(
      180deg,
      transparent,
      rgba(17%, 47%, 55%, 0.7) 60%
    );
  }
}

.truncate-text-inactive {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(180deg, transparent, $gray-500 60%);
  }
}

.main-color {
  color: $app-color-text;
}

.fixed-input {
  max-width: 50%;

  @media (max-width: 1000px) {
    min-width: 350px;
    max-width: 100%;
  }
}

.pointer {
  cursor: pointer !important;
}

.app-color-light {
  background-color: $app-color-light;
}

.app-color-dark {
  background-color: $app-color-dark;
}

.block-array-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
  }
}

.fit-content {
  width: fit-content;
}

.light-gray {
  color: #cfd4d9;
}

.cursor-default {
  cursor: default !important;
}

//buttons
.btn-add {
  width: fit-content;
  background: #1eb28e;
  color: white !important;
  border: 2px solid transparent !important;
  transition: 0.3s;
  &:hover {
    background: #1c9477;
  }
  &:active {
    background: #1eb28e !important;
    border: 2px solid rgba(28, 148, 119, 0.5) !important;
  }
  &:focus {
    background: #1c9477;
    outline: 2px solid rgba(28, 148, 119, 0.5) !important;
  }
  &:disabled {
    background: #6fd1b9;
  }
}

.app-btn-add {
  background: none;
  color: #4B6687;
  border: 1px solid #4B6687;
  border-radius: 4px;
  &:hover {
    color: #FFF;
    background-color: #4B6687;
  }
  &:disabled {
    opacity: 50%;
  }
}

.btn-delete {
  color: red;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: red;
  }
}

.app-btn-main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  height: 34px;
  padding: 12px 20px;
  border-radius: 4px;
  color: #ffffff;
  border: 2px solid transparent;
  transition: 0.3s;
  &:hover {
    color: #ffffff;
  }
}

.app-btn-large {
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
}

.app-btn-apply {
  background: #4b6687;
  &:hover {
    background: #2c405a;
  }
  &:focus {
    background: #2c405a;
    outline: 2px solid rgba(75, 102, 135, 0.5);
  }
  &:active {
    background: #4b6687;
    border: 2px solid rgba(75, 102, 135, 0.5) !important;
  }
  &:disabled {
    background: #a7b9d5 !important;
    border: 2px solid transparent !important;
  }
}

.app-btn-reset {
  background: #40a2b5;
  &:hover {
    background: #397b8e;
  }
  &:focus {
    background: #397b8e;
    outline: 2px solid rgba(64, 162, 181, 0.5) !important;
  }
  &:active {
    background: #40a2b5 !important;
    border: 2px solid rgba(64, 162, 181, 0.5) !important;
  }
  &:disabled {
    background: #aee2f1 !important;
    border: 2px solid transparent !important;
  }
}

.app-color-red {
  color: #e95050 !important;
}

.app-btn-delete {
  background: #e95050;
  &:hover {
    background: #c14242;
  }
  &:focus {
    background: #c14242;
    outline: 2px solid rgba(233, 80, 80, 0.5);
  }
  &:active {
    background: #e95050;
    border: 2px solid rgba(233, 80, 80, 0.5) !important;
  }
  &:disabled {
    background: #f48a8a;
    border: 2px solid transparent !important;
  }
}

.app-btn-cancel {
  background: #8e9094;
  &:hover {
    background: #6a6b6d;
  }
  &:focus {
    background: #6a6b6d;
    outline: 2px solid rgba(142, 144, 148, 0.5);
  }
  &:active {
    background: #8e9094;
    border: 2px solid rgba(142, 144, 148, 0.5);
  }
  &:disabled {
    background: #cfd4d9;
    border: 2px solid transparent !important;
  }
}

.app-btn-full-screen {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-clean {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.app-rightbar {
  transition: width 0.3s, min-width 0.3s, opacity 0.3s, transform 0.3s;
  background-color: white;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 1000;
  flex-shrink: 0;
  height: calc(100vh - 58px);
  position: absolute;
  top: 0;
  right: 0;
  direction: ltr;
  width: 33%;
  min-width: 600px;
  max-width: calc(100vw - 285px);

  .card-header {
    background-color: #ecf5fd !important;
  }
}

.app-rightbar-backdrop {
  transition: opacity 1000ms ease;
  opacity: 1;
}

.app-rightbar-backdrop-active {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  background: rgba(9, 30, 66, 0.54);
}

.app-rightbar-resizer {
    cursor: ew-resize !important;
    height: calc(100vh - 58px);
    position: absolute;
    width: 4px;
    background-color: #A7B9D5;
    left: 0;
    top: 0;
}

.app-rightbar .accordion {
  border-radius: 0;
  height: auto;
}

.app-rightbar-full-screen {
  min-width: 100% !important;
}

.app-actions-bar {
  min-width: 55rem !important;
}

.app-schedule-bar {
  width: 50rem;
}

.btn-apply,
.t4b-bg-dark-button {
  @include button-variant(
    $app-color-dark,
    $app-color-dark,
    darken($app-color-dark, 7.5%),
    darken($app-color-dark, 10%),
    darken($app-color-dark, 10%),
    darken($app-color-dark, 12.5%)
  );
  span {
    color: white;
    font-weight: 500;
  }
}

.btn-up,
.btn-down {
  color: black;
}

.fw-500 {
  font-weight: 500;
}

.fw-450 {
  font-weight: 450;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

//switch
.form-check.custom-switch-md {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer !important;
}

.form-check.custom-switch-md .form-check-input {
  cursor: pointer !important;
}

.form-check.custom-switch-md .form-check-input {
  width: calc(2rem + 0.75rem) !important;
  height: 1.5rem !important;
}

.form-check.custom-switch-md.form-switch .form-check-input {
  background-color: #cfd4d9 !important;
  border: 1px solid #cfd4d9 !important;
}

.form-check.custom-switch-md.form-switch .form-check-input:checked {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.block-switch .form-check.custom-switch-md.form-switch .form-check-input {
  background-image: url('assets/icons/color-1-circle.svg');
}
.block-switch .form-check.grey-switch-md.form-switch .form-check-input {
  background-image: url('assets/icons/color-1-circle.svg');
}

.block-header-default,
  .form-check.custom-switch-md.form-switch,
  .form-check-input {
  background-image: url('assets/icons/color-1-circle.svg');
}
.block-header-default,
  .form-check.grey-switch-md.form-switch,
  .form-check-input {
  background-image: url('assets/icons/color-grey-circle.svg');
}

.block-header-2 .form-check.custom-switch-md.form-switch .form-check-input {
  background-image: url('assets/icons/color-2-circle.svg');
}
.block-header-2 .form-check.grey-switch-md.form-switch .form-check-input {
  background-image: url('assets/icons/color-grey-circle.svg');
}

.block-header-3 .form-check.custom-switch-md.form-switch .form-check-input {
  background-image: url('assets/icons/color-3-circle.svg');
}
.block-header-3 .form-check.grey-switch-md.form-switch .form-check-input {
  background-image: url('assets/icons/color-grey-circle.svg');
}

.block-header-4 .form-check.custom-switch-md.form-switch .form-check-input {
  background-image: url('assets/icons/color-4-circle.svg');
}
.block-header-4 .form-check.grey-switch-md.form-switch .form-check-input {
  background-image: url('assets/icons/color-grey-circle.svg');
}

.block-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  background-color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.error-toast {
  width: 225px;
  word-wrap: break-word;
}

.css-g1d714-ValueContainer,
.css-yk16xz-control,
.multi-select-container {
  max-width: 100%;
}

.app-table {
  border-top: none;
  border-radius: 0 !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

//Animated Container
.animated {
  transition: opacity 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms,
    transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
}
.in-progress {
  transform: translateY(40px);
  opacity: 0;
}
.completed {
  // transform: translateY(0);
  opacity: 1;
}
.loading-appear {
  transform: translateY(40px);
  opacity: 0;
}
.loading-appear-active {
  transition: opacity 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms,
    transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
  transform: translateY(40px);
  opacity: 0;
}
.loading-enter {
  transform: translateY(0);
  opacity: 1;
}
.loading-enter-active {
  transition: opacity 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms,
    transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
  transform: translateY(40px);
  opacity: 0;
}
.loading-enter-done {
  transform: translateY(40px);
  opacity: 0;
}
.loading-exit {
  transform: translateY(40px);
  opacity: 0;
}
.loading-exit-active {
  transition: opacity 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms,
    transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
  transform: translateY(0);
  opacity: 1;
}

.summary-nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 135%;
  color: #2c405a;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 3px;
  text-decoration: none;
  border-bottom: 4px solid white;
  transition: 0.2s cubic-bezier(0.8, 0, 0.6, 1) 0ms,
    transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;

  &:hover {
    color: #2c405a;
  }
}

.summary-nav-link.active {
  border-bottom: 4px solid #2c405a;
  transition: 0.2s cubic-bezier(0.8, 0, 0.6, 1) 0ms,
    transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
}

.app-opacity-70 {
  opacity: 0.7;
}

.app-color-main {
  color: #2c405a;
}

.app-color-group {
  color: #1c3451;
}

.app-color-faded {
  color: #1C3451B2;
}

.bg-blur {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
}

.icon-absolute {
  position: absolute;
  right: 8px;
  top: 8px;
}

.app-no-pseudo {
  &::before,
  &::after {
    display: none !important;
  }
}

.sidebar-scroll {
  .os-scrollbar-track.os-scrollbar-track-off {
    background: #74a2b0;
    left: -243px !important;
    width: 4px;
    border-radius: 5px;
  }

  .os-scrollbar-handle {
    background: white !important;
  }
}

.logs {
  &-date-time {
    .datePicker {
      width: 100% !important;
      height: 42px;
    }
  }
}

.product-visibility-dropdown {
  .header {
    display: flex;
    align-items: center;
    cursor: pointer !important;

    .icon {
      color: #324766 !important;
      margin-right: 8px;
      width: 15px;
      height: 20px;
    }

    .label {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #212529;
      line-height: 1.5;
    }
  }

  .product-dropdown-item {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 17px;
    padding-left: 3px;
    padding-right: 5px;
    cursor: pointer !important;

    label {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #212529;
      line-height: 1.5;
      cursor: pointer !important;
    }
    &:hover {
      box-shadow: 0 4px 4px rgba(50, 71, 102, 0.15);
    }

    .icon {
      color: rgba(50, 71, 102, 0.6);
    }
  }

  .no-data {
    font-size: 18px;
    line-height: 135%;
    color: gray;
    opacity: 0.7;
    padding-bottom: 5px;
  }
}

.add-user-modal {
  .modal-content {
    width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.viewOnly {
  pointer-events: none;
  opacity: 0.8;
}

.alwaysActive {
  pointer-events: auto !important;
}

.react-datepicker {
  display: inline-flex !important;
}

.array-item-header {
  height: 2.6rem;
}

.rule-inactive {
  background-color: #CFD2D7;
  color: black;
}

.rule-inactive-block {
  background-color: #9299A4;
  color: black;
}

.inactive-bg {
  background-color: #F4F4F4;
  color: black;
}

.p-active,
.p-idle,
.p-changing,
.p-inactive {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 5px;
}

.p-active {
  background-color: #6FD1B9;
}

.p-idle {
  background-color: #F0E478;
}

.p-changing {
  background-color: #CFD4D9;
}

.p-inactive {
  background-color: #F48A8A;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-item:active {
  color: unset !important;
  text-decoration: unset !important;
  background-color: unset !important;
}

.chip-ellipsis {
  position: absolute;
  bottom: -0.1rem;
  left: 1rem;
}

.zindex-1000 {
  z-index: 1000;
}

.rounded-blue-btn {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  background-color: #4A5D8C;
  &:hover {
    background-color: #2c405a;
  }
  &:disabled {
    background-color: rgba(74, 93, 140, 0.5);
  }
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  margin-top: 4px;
  padding-left: 0.375rem;
}

.error-in-frame {
  background-color: #E95050;
  border-radius: 4px;
  border: 1px solid #C14242;
  color: #fff;
}

.table-round {
    width: 100%;
    margin: 1.5rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.thead-round {
  border: 1px solid #E8E8E8;
}

.th-round {
  font-size: 12px;
  font-weight: normal;
  padding: 1rem;
  color: rgba(44, 64, 90, 0.65);
}

.tr-round {
  border: 1px solid #E8E8E8;
}

.tcell-round {
  padding: 1rem;
  vertical-align: unset;
}

.tcell-round-content {
  min-width: 10rem;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}